<template>
  <template v-if="$store.state.sysActive.registered == true && $store.state.system.dev == false">
    <LoginPage v-if="!$store.state.logged"/>
    <template v-if="$store.state.logged">
      <NavBar />
      <TopBar />
      <div class="container" :class="{active: $store.state.navbar}">
        
        <router-view :key="this.$route.fullPath" />
      </div>
    </template>
  </template>
  <template v-else>{{ $store.state.sysActive.text }}</template>
  <Transition name="slide-fade">
        <Alerta v-if="$store.state.textA != null" :text='$store.state.textA' />
      </Transition>
    <Alerta v-if="$store.state.textW != null" :text='$store.state.textW' color="yellow" />
    <Alerta v-if="$store.state.textR != null" :text='$store.state.textR' color="req" />
</template>

<script>
import LoginPage from '@/views/login.vue'
import TopBar from '@/components/topbar.vue'
import NavBar from '@/components/navbar.vue'
import axios from 'axios';
import Alerta from '@/components/utils/alerta.vue'

export default {
  name: 'app',
  components: {
    TopBar,
    NavBar,
    LoginPage,
    Alerta
  },
  watch:{
    // $route (to, from){
      // sai de from e vai para to
      // console.log(to, from);
    // }
  },
  methods: {
    async init() {
      const req = await axios.get('start');
      let login = req.data.login;
      let system = req.data.system;

      this.$store.state.system = {...this.$store.state.system,
        color1: system.color1,
        color2: system.color2,
        logo: system.logo,
        dev: process.env.NODE_ENV == 'development' ? false : system.dev
      }
      this.$store.commit('tema');

      const active = await axios.get('https://api.pauloferraco.com.br/v3/ppanel/rights');
      this.$store.state.sysActive =  active.data

      if(this.$store.state.sysActive.registered){
        this.$store.state.logged = login.logged
       
        if(login.logged == true){
          this.$store.state.system.title = 'Olá, '+login.user.user
          this.$store.state.user = {
            id: login.user.id,
            user: login.user.user,
            image: login.user.image,
            modder: login.user.modder
          }
          this.$store.commit('Alert', login.text);
        }
      }
    },
    async verifyLogged(){
      const req = await axios.get('start')
    
      if(req.data.login.logged == false){
        this.$store.commit('Alert2', 'Você está deslogado!')
      }
    },
  },
  mounted () {
    this.init();
    setInterval(() => {
      this.verifyLogged()
    }, 300000);

    if(localStorage.getItem('dark') != null){
      this.$store.commit('dark', localStorage.getItem('dark') == 'true' ? true : false)
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
	font-family: 'Montserrat', sans-serif;
	background-color: #f2faff;
  transition: all .3s linear
}
body.dark-mode {
  background-color: #101018;
}

* {padding: 0;margin: 0;box-sizing: border-box;}

button,textarea,li,ul,select,input,hr {border:0;list-style: none;background-color: transparent;outline: none;color: inherit;text-decoration: none;font-family: 'Montserrat', sans-serif;}
a {display: inherit;text-decoration: none;color: inherit;}
input[type=radio] {display: none;}
input[type=file] {padding: 0 10px !important;font-size: 14px;}
audio {width: 440px;}
textarea {resize: vertical;}

.nobr {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}

#app {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
  z-index: 0;
}

:root {
  --color1: #277ee2;
  --color2: #f2faff;
  --warning: rgb(245, 233, 71);
  --dark: #101018;
}

.container {
  position: relative;
  width: calc(100% - 150px);  
  height: auto;
  top: 66px;
  left: 100px;
  padding: 20px;
  transition: all .5s ease;
  z-index: 2;
}
.container.active {padding-left: 15%;}

.container .top {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-end;
}
.container .top.left {justify-content: flex-start;}

.container .top button {
  position: relative;
  top: -1px;
  width: 42px;
  height: 42px;
  color: #fff;
  font-size: 15px;
  background-color: var(--color1);
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
  border: 2px solid var(--color1);
  transition: all .4s ease;
  margin-right: 8px;
}
.container .top button:hover {border-color: var(--color1);background-color: #fff;color: var(--color1);}
.container .top.left button {left: 0;}

.container .top input,
.container .top select {
  position: relative;
  width: 260px;
  height: 40px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
  padding: 10px 35px 10px 10px;
  font-size: 14px;
  font-weight: 500;
}
.container .top input::placeholder {font-size: 14px;font-weight: 500;}
.container .top select {width: auto;padding-left: 10px;margin-right: 8px;}

.container .top  .fa-magnifying-glass {
 position: absolute;
 top: 12px;
 right: 14px;
 color: #444;
 font-size: 15px;
}

.container > div {
  position: relative;
  display: grid;
  justify-items: start;
}

.container > div > .content {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
}

.container > div > .info {
  position: relative;
  width: auto;
  padding: 6px 20px;
  background-color: rgba(241, 237, 14, 0.3);
  border-radius: 10px;
  font-size: 14px;
}

table {
  position: relative;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  border-radius: 10px;
  color: #333;
}

table tr {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
 
  font-size: 14px;
}
table tr:first-of-type {height: 50px;}
table tr:nth-child(2n) {background-color: rgba(0,0,0,.02);}

table tr:first-of-type {font-size: 16px;}
table tr i {cursor: pointer;}
table tr:first-of-type i {cursor: inherit;}

table > tr > th p {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

table tr th.sort::after {
  content: '';
  position: relative;
  top: 12px;
  margin: 0 0 0 6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #444444 transparent transparent transparent;
}

table tr th.dessort::after {
  content: '';
  position: relative;
  top: -12px;
  margin: 0 0 0 6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent #444444 transparent;
}

table tr td {
  width: auto;
  height: 40px;
  padding: 0 10px;
  text-align: center;
  font-weight: 500;
}
table tr td:first-of-type {font-weight: 600;}

table tr td img{
  padding: 10px 0;
  max-height: 300px;
  max-width: 300px;
}


table tr td input[type="range"] {
  width: 36px;
  height: 20px;
  background-color: #ccc;
	border-radius: 10px;
  margin: 14px 0 0 0;
}

table .status {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-top: 8px;
}
table .status input {display: none;}

table .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 44px;
  height: 24px;
  border-radius: 20px;
  background-color: #ccc;
  transition: all .4s ease;
  transition: .4s;
}

table .slider:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 20px;
  transition: all .4s ease;
}

tr .status input:checked + .slider {
  background-color: var(--color1);
}

tr .status input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

tr .status input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}


.form-page {
  position: relative;
  padding: 10px;
}

.form-page .box {  
  width: 100%;
  height: auto;
  margin: 10px 0;
}

.form-page .box img {
  display: block;
  width: 300px;
  height: auto;
  margin-top: 10px;
}

.form-page span {
  position: relative;
  width: 100%;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
}

.form-page input,
.form-page select,
.form-page textarea {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
}
.form-page input::placeholder {font-size: 14px;}
.form-page input[type="file"] {padding: 10px !important;}
.form-page textarea:focus {border: 1px solid var(--color1)}

.form-page select option {color: #000;}
.form-page button {
  width: auto;
  height: 40px;
  background-color: var(--color1);
  border-radius: 10px;
  padding: 0 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 15px;
}

/* pagination */

.pagination {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.pagination button {
  width: 36px;
  height: 40px;
  height: 100%;
  background-color: var(--color1);
  color: #fff;
  border-radius: 8px;
}

.pagination ul {
  width: auto;
  position: relative;
}

.pagination li button {
  width: 36px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  border-radius: 8px;
  margin: 0 2px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.pagination li button.active-page {background-color: #eee;box-shadow: 0 1px 5px rgba(0,0,0,.15);}
.pagination li button:hover {background-color: #fff; color: var(--color1);box-shadow: inset 0 0 0 2px var(--color1);}

.pagination li button .back-button,
.pagination li button .next-buttom {font-size: 12px;}

.pagination .date {
  width: auto;
  height: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 40px;
  padding: 0 16px;
  background-color: var(--color1);
  border-radius: 10px;
  margin-right: 6px;
  cursor: pointer;
}
.pagination .date:disabled {opacity: .7;}

/* MODAL */

.modal,
.modal .empty {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  transition: all .5s ease;
}
.modal.active {width: calc(100% - 340px);left: 340px;}

.modal > .empty {
  position: absolute;
  background-color: rgba(0,0,0,.15);
  z-index: 0;
}

.modal > .box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
  transform: translate(-50%, -50%);
  padding-bottom: 10px;
}

.modal .top {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
}

.modal .top .title {font-weight: 600;font-size: 16px;}

.modal .content {
  width: 100%;
  height: auto;
  max-height: 700px;
  margin-top: 0;
  overflow-y: auto;
  padding: 0 10px;
}

.modal .content::-webkit-scrollbar{width: 5px;}
.modal .content::-webkit-scrollbar-button { display: none; }
.modal .content::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0); }
.modal .content::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.3);
  width: 5px;
  border-radius: 4px;
}

.modal .content,
.modal .content form {
  position: relative;
  width: 100%;
  height: auto;
}

.modal .content span {font-weight: 600;}

.modal .content span,
.modal .content form input,
.modal .content form select,
.modal .content form textarea {
  float: left;
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.modal .content form input::placeholder,
.modal .content form select,
.modal .content form textarea::placeholder {font-size: 12px;}

.modal .content input,
.modal .content form select,
.modal .content form textarea {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid rgba(0,0,0,.1);
}


.modal .content form textarea {
  height: 60px;
  min-height: 60px;
  max-height: 180px;
  line-height: 20px;
  resize: vertical;
}

.modal .content form select {width: auto;}

.modal .content form select option {
  color: #000;
  padding: 4px 0;
}

.modal .content .button {
  float: left;
  right: 0;
  top: 0;
  width: 100%;
  height: 44px;
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}

.modal .content button {
  position: relative;
  width: auto;
  height: 40px;
  background-color: var(--color1);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
  border-radius: 8px;
  transition: all .3s ease-in-out;
}
.modal .content button:hover {background-color: #fff;box-shadow: 0 0 0 2px var(--color1) inset; color: var(--color1);}

.modal .content form .label {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 178px;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
}
.modal .content form .label::-webkit-scrollbar{width: 4px;}
.modal .content form .label::-webkit-scrollbar-button { display: none; }
.modal .content form .label::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0); }
.modal .content form .label::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.3);
  width: 4px;
  border-radius: 4px;
}

.modal .content form .label label {
  width: auto;
  height: 40px;
  background-color: #eee;
  border-radius: 8px;
  color: #444;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  user-select: none;
  margin: 4px 4px 0 0;
  transition: all .3s ease;
}
.modal .content form .label label.active,
.modal .content form .label label:hover {background-color: var(--color1);color: #fff;}

.modal .content form .label span {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  border-radius: 8px;
}

.modal .content form input[type=checkbox] {display: none;}
.modal .content form input[type=checkbox]:checked ~ span {background-color: var(--color1);color: #fff;}

.modal .content .cancel {
  background-color: #fff;
  box-shadow: 0 0 0 2px var(--color1) inset;
  color: var(--color1);
  transition: all .6s ease-in-out;
}
.modal .content .cancel:hover {background-color: var(--color1) inset; color: #fff;background-color: var(--color1);}

.modal .content form .info {
  font-size: 13px;
  font-weight: 500;
  width: auto;
  height: 30px;
  padding: 0 10px;
  border-radius: 10px;
  line-height: 30px;
  background-color: var(--warning);
  color: #444;
  margin-bottom: 8px;
}

.modal .content form img {
  float: left;
  margin: 6px 0 6px 0;
  max-width: 100%;
  max-height: 250px;
}

.modal .content form .text {
  text-align: center;
  line-height: 20px;
  font-size: 13px;
}

.modal .content form > .box {
  float: left;
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: rgba(33, 150, 243, .1);
  border-radius: 6px;
  margin-top: 30px;
}

.modal .content form > .box input {background-color: #fff;}

/* DARK */
body.dark-mode .modal .box,
body.dark-mode .form-page,
body.dark-mode table {background-color: #3b3b3b;color: #fff !important;border-radius: 10px;}

body.dark-mode .modal input::placeholder {color: #fff;}

/* ANIMAÇÕES */

.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.popup-enter-active {
  animation: popup-in 0.4s;
}
.popup-leave-active {
  animation: popup-in 0.5s reverse;
}
@keyframes popup-in {
  0% {
    opacity: 0;
    right: -100%;
  }
  100% {
    opacity: 1;
    right: 10px;
  }
}

@media only screen and (max-width: 900px) {
  audio {width: 200px;}

  .container {
    width: 100% !important;
    left: 0 !important;
  }
  .container.active {padding: 0;}

  .modal .box {width: calc(100% - 20px);}

  table tr th,
  table tr td {font-size: 12px;max-width: 100px;}
  table tr th:first-of-type {font-size: 14px;max-width: 100px;}
  table tr td:first-of-type {font-size: 13px;max-width: 100px;}

  .pagination {
    height: auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .pagination .date {margin: 4px 0 0 4px; padding: 0 8px;}

  .container .dash  {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .container .dash .item {width: 100%;}

  .container .dash aside {margin-bottom: 10px;width: 100%;}
}

@media only screen 
	and (min-width: 900px) 
	and (max-width: 1000px) {
    audio {width: 300px;}

    .container {
    width: calc(100% - 140px);
    left: 100px;
  }
  .container.active {padding-left: 20px;}

}

@media only screen 
	and (min-width: 1000px) 
	and (max-width: 1400px) {

  audio {width: 350px;}

  .container {
    width: calc(100% - 140px);
    left: 100px;
  }
  .container.active {padding-left: 20%;}

  table tr th,
  table tr td {font-size: 12px;max-width: 100px;}
  table tr th:first-of-type {font-size: 14px;max-width: 100px;}
  table tr td:first-of-type {font-size: 13px;max-width: 100px;}

  table tr td img { max-width: 240px;}
}

</style>